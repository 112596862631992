
import{Routes,Route} from'react-router-dom'; 
import Home from "./Pages/Home/Home";
import Registration from "./Pages/LoginPages/Registration/Registration";
import Connection from "./Pages/LoginPages/Connection/Connection";
import ForgotPassword from "./Pages/LoginPages/ForgotPassword/ForgotPassword";
import MyJourneys from "./Pages/EditorPages/MyJourneys/MyJourneys";
import KindOfJourney from "./Pages/EditorPages/KindOfJourney/KindOfJourney";

function App() {
  return (
    
      <>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/inscription" element={<Registration/>}/>
        <Route path="/connexion" element={<Connection/>}/>
        <Route path="/connexion/motdepasseoublie" element={<ForgotPassword/>}/>
        <Route path="/mesparcours" element={<MyJourneys/>}/>
        <Route path="/typesdeparcours" element={<KindOfJourney/>}/>
    </Routes>
      
    </>
      
  );
}
export default App;
