import Cookies from 'js-cookie';
import { API_URL } from "Utils/config";
import { submitToHubSpotWithTracking } from "Services/RegistrationService.js";




// ConnectionService.js
export async function connectUser(email, password) {

  const url = `${API_URL}/users/authentication/${email}&${password}`;
  const response = await fetch(url);

  if (!response.ok) {
    return { success: false, message: "Une erreur s'est produite lors de la connexion, veuillez réessayer." };
  }

  const reponseServeur = await response.json();

  if (!reponseServeur.keyUser) {
    return { success: false, message: "Compte ou mot de passe invalide." };
  }
  const keyUser = reponseServeur.keyUser;

  // set the keyUser in a cookie
  Cookies.set('keyUser', keyUser);
  return { success: true, keyUser: keyUser };

}

export async function forgottenPassword(email) {

  const url = `${API_URL}/users/forgottenPasswordScenarist`;
  const requestOptions = {
    method: "POST",
    body: email,
  };
  const response = await fetch(url, requestOptions);

  console.log(response);

  if (!response.ok) {
    return { success: false, message: "Une erreur s'est produite" };
  }

  return { success: true };

}

export async function googleLog(credential, form) {
  try {
    const response = await fetch(`${API_URL}/users/googleAuthentication?code=` + credential);
    const userString = await response.text();
    // Convertir la chaîne de caractères JSON en objet JavaScript
    const userObj = JSON.parse(userString);
    const keyUser = userObj.keyUser;
    const email = userObj.email;
    const isNewUser = userObj.newUser;

    if (email !== "" && isNewUser) {
      console.log("submitToHubSpotWithTracking")
      await submitToHubSpotWithTracking(email);
    }


    if (keyUser !== "") {
      Cookies.set('keyUser', keyUser);
      window.location.href = `https://edit.geo-gaming.com/login/ListScenario.php?keyUser=${keyUser}`;
    } else {
      form.setFormError('Erreur lors de la connexion Google, veuillez réessayer.');

    }

  } catch (error) {
    form.setFormError('Erreur lors de la connexion Google, veuillez réessayer.');
  }
}




